import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Dropdown, Nav, Navbar } from "react-bootstrap"
import logo1 from "../../assets/images/EoW-Horizontal-logo_TM_noBackgroundcolor.svg"
import ShopModal from "../../components/models/shopmodal"
import DesktopNav from "./desktopnav"
import sysLangs from "../../utils/enums/languages"
import world from "../../assets/images/icons/worldBlack.svg"
import { navigate } from "@reach/router"

const Header = ({ location, siteTitle, path, pageContext: { translate } }) => {
  const dataConsumer = [
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }coffee-roaster/`,
      btnText: translate.t?.roasters,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }estates/`,
      btnText: translate.t?.estates,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }facilitator/`,
      btnText: translate.t?.facilitators,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }micro-roaster/`,
      btnText: translate.t?.specialty_roasters, 
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }consumer/`,
      btnText: translate.t?.end_consumers,
      description: "Be a part of a global movement",
    },

    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }hospitality/`,
      btnText: translate.t?.hospitality_retail,
      description: "Be a part of a global movement",
    },
  ]
  const dataPartner = [
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }aboutus/`,
      btnText: translate.t?.our_mission,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }press-media/`,
      btnText: translate.t?.press_and_media,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }community-news/`,
      btnText: translate.t?.community_news,
      description: "Be a part of a global movement",
    },
  ]
  const dataWe = [
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }sustainability/`,
      btnText: translate.t?.sustainability,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }community/`,
      btnText: translate.t?.our_community,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }traceability/`,
      btnText: translate.t?.a_traceable_experience,
      description: "Be a part of a global movement",
    },
  ]

  const onToggle = event => {
    if (event) {
      document.getElementsByTagName("html")[0].classList.add("overflow-hidden")
    } else {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("overflow-hidden")
    }
  }

  return (
    <>
      {
        <div className="header-navbar secondary">
          <Navbar expand="lg" onToggle={onToggle}>
            <Link
              className="logo w-10"
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }`}
            >
              <img src={logo1} alt="logo" />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Link
                  className="img-dropdown"
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }`}
                >
                  <img src={logo1} alt="logo" />
                </Link>
                <Dropdown className="d-lg-none">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={path === "eraofwe" ? "active" : ""}
                  >
                    {translate.t?.why_era_of_we}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="desktop-dropdown">
                    <DesktopNav data={dataWe} />
                  </Dropdown.Menu>
                </Dropdown>
                <div className="dropdown d-none d-lg-block">
                  <button className="dropbtn">
                    {translate.t?.why_era_of_we}
                  </button>
                  <div className="dropdown-content">
                    {dataWe.map(we => (
                      <Link
                        className="paragraph-p2 text-darker semi"
                        to={`${we.btnLink}`}
                      >
                        {we.btnText}
                      </Link>
                    ))}
                  </div>
                </div>
                <Link
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }solutions/`}
                  className={`parentItem ${
                    path === "solutions" ? "active" : ""
                  }`}
                >
                  {translate.t?.solution}
                </Link>
                <Dropdown className="d-lg-none">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={path === "partners" ? "active" : ""}
                  >
                    {translate.t?.menu_partners}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="desktop-dropdown">
                    <DesktopNav data={dataConsumer} />
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="d-lg-none">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={path === "aboutus" ? "active" : ""}
                  >
                    {translate.t?.about_us}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="desktop-dropdown">
                    <DesktopNav data={dataPartner} />
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dropdown d-none d-lg-block">
                  <button className="dropbtn">
                    {translate.t?.menu_partners}
                  </button>
                  <div className="dropdown-content">
                    {dataConsumer.map(consumer => (
                      <Link
                        className="paragraph-p2 text-darker semi"
                        to={`${consumer.btnLink}`}
                      >
                        {consumer.btnText}
                      </Link>
                    ))}
                  </div>
                </div>

                <div className="dropdown d-none d-lg-block">
                  <button className="dropbtn">{translate.t?.about_us}</button>
                  <div className="dropdown-content">
                    {dataPartner.map(about => (
                      <Link
                        className="paragraph-p2 text-darker semi"
                        to={`${about.btnLink}`}
                      >
                        {about.btnText}
                      </Link>
                    ))}
                  </div>
                </div>
                <a
                  href={`${process.env.GATSBY_SITE_URL}/coffee-lab/?lang=${translate.currentLang}`}
                  className={`parentItem`}
                >
                  {translate.t?.coffee_forum}
                </a>

                <Link
                  className="btndefault whitebtn demo mt-0 d-none d-lg-block"
                  to={`${process.env.GATSBY_SSO_WEB}/login`}
                >
                  Login
                </Link>
                <ShopModal
                  btnText={translate.t?.shop}
                  title={translate.t?.shop_is_not}
                  description={translate.t?.currently_available}
                  submitBtnText={translate.t?.ok}
                />

                <Dropdown className="">
                  <LanguageDropdown location={location} data={translate} />
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
            <Nav className="display-mobile d-md-block d-lg-none">
              <Link
                className="btn-login btndefault greenbtn"
                to={`${process.env.GATSBY_SSO_WEB}/login`}
              >
                Login
              </Link>
            </Nav>
          </Navbar>
        </div>
      }
    </>
  )
}

const LanguageDropdown = ({ data, location }) => {
  let loc = location?.pathname?.split("/")[1]
  if (!["es", "sv", "pt"].includes(loc)) {
    loc = data.currentLang || ""
  }
  const [active, setActive] = useState(loc) 
  const [currentPath, setCurrentPath] = useState("")

  const languages = {
    '': "English",
    sv: "Swedish",
    pt: "Portuguese",
    es: "Spanish", 
    da: "Danish",
    de: "German",
    no: "Norwegian",
  }

  useEffect(() => {
    setCurrentPath(
      window?.location?.pathname
        ?.split("/")
        ?.slice(loc === "" ? 1 : 2)
        .join("/")
    )
  }, [])

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic">
        <img className="h-20 pr-1" src={world} alt="Globe Icon" />
        {languages[active]}
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 cust-lang-color">
        {sysLangs.map((l, i) => (
          <Dropdown.Item
            key={i}
            active={active === l}
            onSelect={() => {
              navigate(`${l ? "/" + l + "/" : "/"}${currentPath}`)
              setActive(l)
            }}
          >
            {languages[l]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
