import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Navbar, Nav, Dropdown } from "react-bootstrap"
import DesktopNav from "./desktopnav"
import consumerdrop from "../../assets/images/consumerdrop.png"
import partner from "../../assets/images/partners.png"
import wedropdown from "../../assets/images/wedropdown.png"
// import flag from "../../assets/images/icons/us_flag.svg"
import logo from "../../assets/images/EoW-Horizontal-logo_TM_noBackgroundcolor.svg"

const HeaderSecond = ({ siteTitle, path, pageContext: { translate } }) => {
  const dataConsumer = [
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }coffee-roaster/`,
      btnText: translate.t?.roaster,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }estates/`,
      btnText: translate.t?.estate,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }facilitator/`,
      btnText: translate.t?.facilitator,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }micro-roaster/`,
      btnText: translate.t?.specialty_roaster,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }consumer/`,
      btnText: translate.t?.end_consumer,
      description: "Be a part of a global movement",
    },

    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }hospitality/`,
      btnText: translate.t?.hospitality,
      description: "Be a part of a global movement",
    },
  ]
  const dataPartner = [
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }aboutus/`,
      btnText: translate.t?.our_mission,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }press-media/`,
      btnText: translate.t?.press_and_media,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }community-news/`,
      btnText: translate.t?.community_news,
      description: "Be a part of a global movement",
    },
  ]
  const dataWe = [
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }sustainability/`,
      btnText: translate.t?.sustainability,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }community/`,
      btnText: translate.t?.our_community,
      description: "Be a part of a global movement",
    },
    {
      btnLink: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }traceability/`,
      btnText: translate.t?.a_traceable_experience,
      description: "Be a part of a global movement",
    },
  ]
  React.useEffect(() => {
    document.body.classList.remove("home-page")
  }, [])

  const onToggle = event => {
    if (event) {
      document.getElementsByTagName("html")[0].classList.add("overflow-hidden")
    } else {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("overflow-hidden")
    }
  }

  return (
    <>
      {
        <div className="header-navbar secondary">
          <Navbar expand="md" onToggle={onToggle}>
            <Link
              className="logo"
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }`}
            >
              <img src={logo} alt="logo" />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Link
                  className="img-dropdown"
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }`}
                >
                  <img src={logo} alt="logo" />
                </Link>

                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={path === "eraofwe" ? "active" : ""}
                  >
                    {translate.t?.why_era_of_we}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="desktop-dropdown">
                    <DesktopNav img={wedropdown} data={dataWe} />
                  </Dropdown.Menu>
                </Dropdown>
                <Link
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }solutions/`}
                  className={`parentItem ${
                    path === "solutions" ? "active" : ""
                  }`}
                >
                  {translate.t?.solution}
                </Link>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={path === "partners" ? "active" : ""}
                  >
                    {translate.t?.menu_partners}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="desktop-dropdown">
                    <DesktopNav img={consumerdrop} data={dataConsumer} />
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={path === "aboutus" ? "active" : ""}
                  >
                    {translate.t?.about_us}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="desktop-dropdown">
                    <DesktopNav img={partner} data={dataPartner} />
                  </Dropdown.Menu>
                </Dropdown>
                <a
                  href={`${process.env.GATSBY_SITE_URL}/coffee-lab`}
                  className={`parentItem`}
                >
                  {translate.t?.coffee_forum}
                </a>
                <Link className="divider" to="#"></Link>
                <a
                  className="btn-login btndefault greenbtn d-none d-md-flex"
                  href={`${process.env.GATSBY_SSO_WEB}/login`}
                >
                  {translate.t?.login}
                </a>

                {/* <Dropdown className="desktop-flag">
              <Dropdown.Toggle id="dropdown-basic">
                <div className="img-glag">
                  <img src={flag} alt="Flag" />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">{translate.t?.action}</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
              </Nav>
            </Navbar.Collapse>
            <Nav className="display-mobile">
              <a
                className="btn-login btndefault greenbtn"
                href="https://sso.eraofwe.com/login"
                rel={
                  process.env.GATSBY_NEED_PROTECT === "true"
                    ? "nofollow noopener noreferrer"
                    : ""
                }
              >
                Login
              </a>

              <Dropdown>
                {/* <Dropdown.Toggle id="dropdown-basic">
              <div className="img-glag">
                <img src={flag} alt="Flag" />
              </div>
            </Dropdown.Toggle> */}

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    {translate.t?.action}
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar>
        </div>
      }
    </>
  )
}

HeaderSecond.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderSecond.defaultProps = {
  siteTitle: ``,
}

export default HeaderSecond
