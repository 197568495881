import axios from "axios"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import close from "../../assets/images/icons/close.svg"
import coffeeBeans from "../../assets/images/icons/coffee-beans.png"
import da from "../../assets/images/icons/da-flag.svg"
import arrow from "../../assets/images/icons/icons-dropdown.svg"
import sv from "../../assets/images/icons/sv-flag.svg"
import shopBean from "../../assets/images/shop-bean.webp"
import { Link } from "gatsby"

const ShopModal = props => {
  const { btnText, title, description, submitBtnText } = props
  const [show, setShow] = useState(false)
  const [denmark, setDenmark] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    axios
      .get(
        "https://api.db-ip.com/v2/p8bd6f18bfbe61ef8dc4f969b761b3f1871df24f/self"
      )
      .then(result => {
        if (result?.data.countryCode === "SE" || result?.countryCode === "SE") {
          window.open(`${process.env.SWEDEN_SHOP_SITE_URL}`, "_blank")
        } else if (
          result?.data.countryCode === "DK" ||
          result?.countryCode === "DK"
        ) {
          setShow(true)
          setDenmark(true)
        } else {
          setShow(true)
        }
      })
  }
  return (
    <>
      <div className="model-box">
        <div>
          {/* <button
            className={`btndefault greenbtn mt-3 mt-lg-0 mb-4 mb-lg-0 shop`}
            onClick={handleShow}
          >
            {btnText}
          </button> */}

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="shop-dialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {!denmark ? (
              <Modal.Body>
                <div className="d-flex justify-content-end pr-3 pr-md-4 pt-4 mt-1">
                  <img
                    src={close}
                    alt="Close"
                    className="cursor-pointer"
                    onClick={() => handleClose()}
                  />
                </div>
                <div className="text-center mb-4 mt-25">
                  <img src={shopBean} alt="shop" className="shop-beans" />
                </div>
                <div className="details">
                  <p className="paragraph-p1 bold text-medium mb-md-1">
                    {title}
                  </p>
                  <p className="paragraph-p16  text-medium pb-4 pb-md-5">
                    {description}
                  </p>
                </div>
                <Link
                  className="d-flex justify-content-between border-top py-2 px-3 px-md-4 cursor-pointer"
                  to={`${process.env.SWEDEN_SHOP_SITE_URL}`}
                  target="_blank"
                >
                  <div className="d-flex">
                    <img src={sv} width="32" alt="flag" />
                    <span className="align-items-center d-flex fnt-14 fw-bold ml-2 txt-clr223">
                      Sweden
                    </span>
                  </div>
                  <div>
                    <img src={arrow} alt="arrow" />
                  </div>
                </Link>
                <div
                  className="d-flex justify-content-between border-top py-2 px-3 px-md-4 cursor-pointer"
                  onClick={() => setDenmark(true)}
                >
                  <div className="d-flex">
                    <img src={da} width="32" alt="flag" />
                    <span className="align-items-center d-flex fnt-14 fw-bold ml-2 txt-clr223">
                      Denmark
                    </span>
                  </div>
                  <div>
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
                <div className="border-top d-flex justify-content-end">
                  <button
                    className="btndefault greenbtn my-3 mr-3 my-md-4 mr-md-4"
                    onClick={() => handleClose()}
                  >
                    {submitBtnText}
                  </button>
                </div>
              </Modal.Body>
            ) : (
              <Modal.Body>
                <div className="d-flex justify-content-end pt-4 px-3 px-md-4">
                  <img
                    src={close}
                    alt="Close"
                    className="cursor-pointer"
                    onClick={() => handleClose()}
                  />
                </div>
                <div className="text-center mb-4 mt-25">
                  <img
                    src={coffeeBeans}
                    alt="coffee-beans"
                    className="shop-beans"
                  />
                </div>
                <div className="details">
                  <p className="paragraph-p16  text-medium pb-4 pb-md-5 px-4">
                    The Era of We e-commerce site in Denmark is currently being
                    setup. In the meantime, please visit us at the art museum
                    ARoS in Aarhus and take the opportunity to try the exclusive
                    Peter Larsen Kaffe-brewed coffee made on beans from the Sipi Falls
                    in Uganda.
                  </p>
                </div>
              </Modal.Body>
            )}
          </Modal>
        </div>
      </div>
    </>
  )
}

export default ShopModal
