import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Headersecond from "./Headersecond"
import Footer from "./footer"
import logo from "../../assets/images/EoW-Horizontal-logo_TM_noBackgroundcolor.svg"
import "../../assets/css/index.scss"
import Cookies from "universal-cookie"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import en from "../../assets/translations/en.json"

const cookies = new Cookies()

const Layout = ({
  children,
  secondary,
  path,
  location,
  campaign,
  SEO,
  pageContext: { translate },
}) => {
  const [password, setPassworrd] = useState("")

  const params = new URLSearchParams(location.search)

  const [utm_medium, utm_source, utm_campaign] = [
    params.get("utm_medium"),
    params.get("utm_source"),
    params.get("utm_campaign"),
  ]

  if (utm_medium) {
    cookies.set("utm_medium", utm_medium, { path: "/" })
  }

  if (utm_source) {
    cookies.set("utm_source", utm_source, { path: "/" })
  }

  if (utm_campaign) {
    cookies.set("utm_campaign", utm_campaign, { path: "/" })
  }

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.remove("overflow-hidden")
  }, [])
  return (
    <>
      <SEO
        data={{
          t: en,
        }}
      />

      {password === "Upwork321" ||
      process.env.GATSBY_NEED_PROTECT === "false" ? (
        <GoogleReCaptchaProvider reCaptchaKey="6LcFa4gbAAAAAMxht2hgjgQmREJ-OdOxxZ-0CuP6">
          <Header siteTitle={"Title"} path={path} pageContext={{ translate }} />
          <main>{children}</main>
          {!campaign && (
            <Footer pageContext={{ translate }} location={location} />
          )}
        </GoogleReCaptchaProvider>
      ) : (
        <div className="form-password ">
          <img src={logo} alt="Era of We" class="w-10" />
          <input
            type="password"
            placeholder="enter password ..."
            onChange={e => setPassworrd(e.target.value)}
          />
          <main className="d-none">{children}</main>
        </div>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
}

export default Layout
