import React from "react"
import { Link } from "gatsby"
const DesktopNav = ({ data }) => {
  return (
    <div className="desktop-nav">
      <div className="">
        <div className="option-links">
          {data?.map((content, count) => (
            <div key={count} className="box">
              <Link
                className="paragraph-p2 text-darker semi"
                to={content.btnLink}
              >
                {content.btnText}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DesktopNav
