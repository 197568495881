import React, { Component } from "react";
import ReactDOM from "react-dom";
import makeAnimated from "react-select/animated";
import { userOptions, defaultOption } from "./data.js";
import MySelect from "./MySelect.js";
import { components } from "react-select";

const Option = props => {
  return (
    <div className="dropdownContainer">
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: '5px', backgroundColor: 'black', height: '18px', width: '18px', backgroundcolor:"none" }}
            className="dropdownCheckbox "
        />{" "}
        <label style={{ color: 'black' }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();
export default class NewsletterDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
      dropdownOpen: false,
    };
  }

  handleChange = selected => {
    this.setState(
      {
        optionSelected: selected,
      },
      () => {
        if (this.props.onOptionsSelected) {
          this.props.onOptionsSelected(selected);
        }
      }
    );
  };
  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  toggleDropdownOnTouch = () => {
    if (!this.state.dropdownOpen) {
      this.setState({ dropdownOpen: true });
    }
  }

  componentDidMount() {
    this.setState({ optionSelected: defaultOption });
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("touchstart", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("touchstart", this.handleClickOutside);
  }

  render() {
    const { dropdownOpen, optionSelected } = this.state;
    return (
      <div ref={(ref) => (this.dropdownRef = ref)}>
      <div onClick={this.toggleDropdown} onTouchStart={this.toggleDropdownOnTouch}>
      <MySelect
      className="w-80 css-2b097c-container"
        options={userOptions}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option, MultiValue, animatedComponents }}
        onChange={this.handleChange}
        allowSelectAll={true}
        value={this.state.optionSelected}
        menuIsOpen={dropdownOpen}
        placeholder="Which newsletters are you interested in?"
      />
      </div>
      </div>
    );
  }
}


