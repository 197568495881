import * as React from "react"
import { Link } from "gatsby"

const FooterLinks = ({ pageContext: { translate } }) => {
  return (
    <div className="footer-links">
      <div className="each-section">
        <h2>{translate.t?.menu_partners}</h2>
        <ul>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }coffee-roaster/`}
            >
              {translate.t?.roasters}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }estates/`}
            >
              {translate.t?.estates}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }facilitator/`}
            >
              {translate.t?.facilitators}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }hospitality/`}
            >
              {translate.t?.hospitality}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }micro-roaster/`}
            >
              {translate.t?.specialty_roaster} 
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }consumer/`}
            >
              {translate.t?.consumers}
            </Link>
          </li>
        </ul>
      </div>

      <div className="each-section">
        <h2>{translate.t?.company}</h2>
        <ul>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }contact/`}
            >
              {translate.t?.contact_us}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }press-media/`}
            >
              {translate.t?.press_and_media}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }privacy-policy/`}
            >
              {translate.t?.privacy_policy}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }terms-and-condition/`}
            >
              {translate.t?.terms_and_conditions}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }cookie-policy/`}
            >
              {translate.t?.cookie_policy}
            </Link>
          </li>
        </ul>
      </div>

      <div className="each-section">
        <h2>{translate.t?.about}</h2>
        <ul>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }aboutus/`}
            >
              {translate.t?.our_mission}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }solutions/`}
            >
              {translate.t?.solution}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }sustainability/`}
            >
              {translate.t?.sustainability}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }traceability/`}
            >
              {translate.t?.public_the_traceability_experience}
            </Link>
          </li>
        </ul>
      </div>

      <div className="each-section">
        <h2>{translate.t?.resources}</h2>
        <ul>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }community-news/`}
            >
              {translate.t?.community_news}
            </Link>
          </li>
          <li>
            <Link
              to={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }price-crisis/`}
            >
              {translate.t?.price_crisis}
            </Link>
          </li>
          <li>
            <Link to="https://support.eraofwe.com">{translate?.t?.support}</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default FooterLinks
